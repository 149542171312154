var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      !_vm.isStyle
        ? _c("div", [
            _c("div", { staticClass: "marB20 x-bc" }, [
              _c("div", { staticClass: "fontS14c3" }, [_vm._v("导航样式")]),
              _c("div", { staticClass: "x-x" }, [
                _c(
                  "div",
                  {
                    staticClass: "simpleBtn cursorP",
                    class: { active: !_vm.data.contentStyle.isCustom },
                    on: {
                      click: function ($event) {
                        return _vm.getIsCustom(false)
                      },
                    },
                  },
                  [_vm._v(" 默认图标 ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "simpleBtn cursorP",
                    class: { active: _vm.data.contentStyle.isCustom },
                    on: {
                      click: function ($event) {
                        return _vm.getIsCustom(true)
                      },
                    },
                  },
                  [_vm._v(" 自定义 ")]
                ),
              ]),
            ]),
            _c(
              "div",
              [
                _c("div", { staticClass: "marB20 x-bc" }, [
                  _c("div", { staticClass: "fontS14c3" }, [
                    _vm._v(
                      _vm._s(
                        "当前使用：" + (_vm.data.contentStyle.typeName || "")
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "simpleBtn cursorP active",
                      on: {
                        click: function ($event) {
                          _vm.isStyle = true
                        },
                      },
                    },
                    [_vm._v(" 切换样式 ")]
                  ),
                ]),
                _c("div", { staticClass: "x-bc marB20" }, [
                  _c("div", { staticClass: "fontS14" }, [_vm._v(" 导航数量 ")]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          ref: "navNumber",
                          attrs: {
                            size: "mini",
                            filterable: "",
                            placeholder: "请选择",
                            disabled: !_vm.data.contentStyle.isCustom,
                          },
                          on: { change: _vm.handNavNumber },
                          model: {
                            value: _vm.data.contentStyle.navNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.contentStyle, "navNumber", $$v)
                            },
                            expression: "data.contentStyle.navNumber",
                          },
                        },
                        _vm._l([1, 2, 3, 4, 5], function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._l(_vm.data.contentStyle.navList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "iconSettings" },
                    [
                      _c("div", { staticClass: "settinTop x-bc" }, [
                        _c(
                          "div",
                          { staticClass: "y-start" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "marB10",
                                attrs: {
                                  disabled:
                                    (item.type == 0 && index == 0) ||
                                    !_vm.data.contentStyle.isCustom,
                                  size: "mini",
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeNav(index, item.type)
                                  },
                                },
                                model: {
                                  value: item.type,
                                  callback: function ($$v) {
                                    _vm.$set(item, "type", $$v)
                                  },
                                  expression: "item.type",
                                },
                              },
                              _vm._l(_vm.navigation, function (v, i) {
                                return _c("el-option", {
                                  key: i,
                                  attrs: { label: v.name, value: v.type },
                                })
                              }),
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    maxlength: "4",
                                    "show-word-limit": "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: item.label,
                                    callback: function ($$v) {
                                      _vm.$set(item, "label", $$v)
                                    },
                                    expression: "item.label",
                                  },
                                }),
                                item.type == 4
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "x-f",
                                        on: {
                                          click: function ($event) {
                                            return _vm.getOpenLink(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请选择",
                                            readonly: "",
                                          },
                                          model: {
                                            value: item.linkName,
                                            callback: function ($$v) {
                                              _vm.$set(item, "linkName", $$v)
                                            },
                                            expression: "item.linkName",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "marL10 fontS14B cursorP",
                                            staticStyle: { width: "50px" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.linkUrl ? "修改" : "选择"
                                                )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.data.contentStyle.type != 3,
                                expression: "data.contentStyle.type!=3",
                              },
                            ],
                            staticClass: "x-f",
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-right": "20px" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "fontS14c3 iconTitle" },
                                  [_vm._v("普通:")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "itemIcon x-fc",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addImgClick(index, 1)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !item.icon,
                                          expression: "!item.icon",
                                        },
                                      ],
                                      staticClass: "el-icon-plus",
                                      staticStyle: { "font-size": "24px" },
                                    }),
                                    _vm.data.contentStyle.isCustom
                                      ? _c("el-image", {
                                          staticStyle: {
                                            width: "24px",
                                            height: "24px",
                                          },
                                          attrs: { src: item.icon },
                                        })
                                      : _c("iconParkALi", {
                                          attrs: {
                                            name: item.iconName,
                                            size: "24px",
                                            color: "#a5a5a5",
                                          },
                                        }),
                                    _vm.data.contentStyle.isCustom
                                      ? _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.icon,
                                                expression: "item.icon",
                                              },
                                            ],
                                            staticClass: "replace",
                                          },
                                          [_vm._v("替换")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "fontS14c3 iconTitle" },
                                [_vm._v("点亮:")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "itemIcon x-fc",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addImgClick(index, 2)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !item.checkIcon,
                                        expression: "!item.checkIcon",
                                      },
                                    ],
                                    staticClass: "el-icon-plus",
                                    staticStyle: { "font-size": "24px" },
                                  }),
                                  _vm.data.contentStyle.isCustom
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "24px",
                                          height: "24px",
                                        },
                                        attrs: { src: item.checkIcon },
                                      })
                                    : _c("iconParkALi", {
                                        attrs: {
                                          name: item.iconName,
                                          size: "24px",
                                          color: "#fe5933",
                                        },
                                      }),
                                  _vm.data.contentStyle.isCustom
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.checkIcon,
                                              expression: "item.checkIcon",
                                            },
                                          ],
                                          staticClass: "replace",
                                        },
                                        [_vm._v("替换")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                _c("div", { staticClass: "x-bc marB20" }, [
                  _c("div", { staticClass: "x-f" }, [
                    _c("span", { staticClass: "fontS14c3 marR20" }, [
                      _vm._v("普通字体颜色:"),
                    ]),
                    _c("span", { staticClass: "fontS14" }, [
                      _vm._v(_vm._s(_vm.data.contentStyle.textColor)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "x-f marL10" },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.data.contentStyle.textColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.contentStyle, "textColor", $$v)
                          },
                          expression: "data.contentStyle.textColor",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "x-bc marB20" }, [
                  _c("div", { staticClass: "x-f" }, [
                    _c("span", { staticClass: "fontS14c3 marR20" }, [
                      _vm._v("点亮字体颜色:"),
                    ]),
                    _c("span", { staticClass: "fontS14" }, [
                      _vm._v(_vm._s(_vm.data.contentStyle.checkTextColor)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "x-f marL10" },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.data.contentStyle.checkTextColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.data.contentStyle,
                              "checkTextColor",
                              $$v
                            )
                          },
                          expression: "data.contentStyle.checkTextColor",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "x-bc marB20" }, [
                  _c("div", { staticClass: "x-f" }, [
                    _c("span", { staticClass: "fontS14c3 marR20" }, [
                      _vm._v("背景颜色:"),
                    ]),
                    _c("span", { staticClass: "fontS14" }, [
                      _vm._v(_vm._s(_vm.data.contentStyle.bgColor)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "x-f marL10" },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.data.contentStyle.bgColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.contentStyle, "bgColor", $$v)
                          },
                          expression: "data.contentStyle.bgColor",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ])
        : _c("div", [
            _c("div", { staticClass: "marB20 x-b" }, [
              _c("div", { staticClass: "fontS14c3" }, [_vm._v("切换样式")]),
              _c(
                "div",
                {
                  staticClass: "simpleBtn cursorP active",
                  on: {
                    click: function ($event) {
                      _vm.isStyle = false
                    },
                  },
                },
                [_vm._v(" 返回 ")]
              ),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-radio-group",
                  {
                    ref: "radioType",
                    staticClass: "y-start",
                    attrs: { value: _vm.data.contentStyle.type },
                    on: { input: _vm.getType },
                  },
                  _vm._l(_vm.typeList, function (x, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "y-start",
                        staticStyle: { "margin-bottom": "40px" },
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "marB20", attrs: { label: x.type } },
                          [_vm._v(_vm._s(x.typeName))]
                        ),
                        _c("el-image", { attrs: { src: x.url } }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.showDialog,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("selectLink", {
        attrs: { OpenLink: _vm.openLink },
        on: {
          "update:OpenLink": function ($event) {
            _vm.openLink = $event
          },
          "update:open-link": function ($event) {
            _vm.openLink = $event
          },
          selectLinkObj: _vm.selectLinkObj,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }